// Cover.js
import React, { useEffect, useState } from 'react';
import axios from '../services/axios';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import ColorThief from 'colorthief';
import useWindowSize from '../utils/hooks/useWindowSize';
import './cover.css';
import requests from '../services/requests';
import Constants from '../../Constants';
import { removeWatchlistRecord, watchlistRecord } from '../../ApiServices';
import CommonServices from '../../CommonServices';

function Cover({ recordCount = 5, isNavbarCollapsed, onColorChange = () => {} }) {

  // console.log("isNavbarCollapsed", isNavbarCollapsed);

  // Add state fallback
  const [localNavbarState] = useState(() => 
    typeof isNavbarCollapsed === 'boolean' ? isNavbarCollapsed : true
  );

  const [records, setRecords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionClass, setTransitionClass] = useState('');
  const [animating, setAnimating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [coverColor, setCoverColor] = useState('rgba(0,0,0,0.9)');
  const navigate = useNavigate();
  const screenSize = useWindowSize();
  const [isWatchListed, setIsWatchListed] = useState(false);


  const onToggleWatchlist = async () => {
    const record = records[currentIndex];
    if (!isWatchListed) {
      setIsWatchListed(true);
      const response = await watchlistRecord(record.recordId);
      if (response.httpStatusCode === 200) {
        setRecords((prev)=>{
          return prev.map((prevRecord)=>{
            if(prevRecord.recordId === record.recordId){
              return {...prevRecord, isWatchListed: true}
            }
            return prevRecord;
          })
        });
      } else {
        setIsWatchListed(false);
        console.log(response.message);
      }
    } else {
      setIsWatchListed(false);
      const response = await removeWatchlistRecord(record.recordId);
      if (response.httpStatusCode === 200) {
        setRecords((prev)=>{
          return prev.map((prevRecord)=>{
            if(prevRecord.recordId === record.recordId){
              return {...prevRecord, isWatchListed: false}
            }
            return prevRecord;
          })
        }); 
      } else {
        setIsWatchListed(true);
        console.log(response.message);
      }
    }
  };

  useEffect(() => {
    async function fetchCoverMovies() {
      try {
        const response = await axios.get(requests.fetchCoverRecord, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem("token") },
          params: { pageSize: recordCount }
        });
        const recordsData = response.data.data.records.map(record => {
          record.tmdb = record.type === Constants.RECORD_TYPE_MOVIE ? record.movieTmdb : record.seriesTmdb;
          return record;
        });
        setRecords(recordsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cover movies:", error);
        setLoading(false);
      }
    }
    fetchCoverMovies();
  }, [recordCount]);

  const animationDuration = 500; // ms

  const handleNext = () => {
    if (records.length === 0 || animating) return;
    setAnimating(true);
    setTransitionClass('slide-out-left');
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % records.length);
      setTransitionClass('slide-in-right');
      setTimeout(() => {
        setTransitionClass('');
        setAnimating(false);
      }, animationDuration);
    }, animationDuration);
  };

  const handlePrev = () => {
    if (records.length === 0 || animating) return;
    setAnimating(true);
    setTransitionClass('slide-out-right');
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + records.length) % records.length);
      setTransitionClass('slide-in-left');
      setTimeout(() => {
        setTransitionClass('');
        setAnimating(false);
      }, animationDuration);
    }, animationDuration);
  };

  // Extract dominant color and update navbar CSS variable.
  useEffect(() => {
    if (!records.length) return;
    const record = records[currentIndex];
    if (!record?.tmdb) return;
    const img = new Image();
    img.crossOrigin = "anonymous";
    // const imageUrl = `https://image.tmdb.org/t/p/w500/${screenSize.width < 768
    //   ? record.tmdb.poster_path
    //   : (record.tmdb.backdrop_path || record.tmdb.poster_path)
    //   }`;
    img.src = CommonServices.getImageUrlFromTmdb(record?.tmdb, screenSize.width < 768 ? Constants.IMAGE_TYPE_POSTER : Constants.IMAGE_TYPE_BACKDROP, "original");
    img.onload = () => {
      const colorThief = new ColorThief();
      const [r, g, b] = colorThief.getColor(img);
      const color = `rgba(${r},${g},${b},0.5)`;
      document.documentElement.style.setProperty('--navbar-bg-color', color);
      setCoverColor(color);
      
      // Add safety check
      if (typeof onColorChange === 'function') {
        onColorChange(color);
      }
    };
    setIsWatchListed(record?.isWatchListed);
  }, [records, currentIndex, screenSize]);

  // Auto-cycle slides every 10 seconds.
  useEffect(() => {
    if (!records.length) return;
    const interval = setInterval(() => {
      handleNext();
    }, 10000);
    return () => clearInterval(interval);
  }, [records, animating]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    trackMouse: true,
  });

  if (loading) return <CoverSkeleton />;

  const record = records[currentIndex];
  if (!record) return null;

  return (
    <div
      {...handlers}
      className={`cover-container ${localNavbarState ? 'collapsed' : ''}`}
      style={{ background: coverColor }}
    >
      <div
        className={`cover-main ${transitionClass}`}
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url("https://image.tmdb.org/t/p/original/${screenSize.width < 768
            ? record.tmdb.poster_path
            : (record.tmdb.backdrop_path || record.tmdb.poster_path)
            }")`,
          backgroundPosition: "center center",
          backgroundColor: coverColor
        }}
        onClick={() =>
          navigate(
            record.type.toLowerCase() === Constants.RECORD_TYPE_MOVIE
              ? Constants.DB_MOVIE_DETIALS_ROUTE.replace(":title", record.recordId + "-" + record.name.toLowerCase().replace(/ /g, "-"))
              : Constants.DB_SERIES_DETIALS_ROUTE.replace(":title", record.recordId + "-" + record.name.toLowerCase().replace(/ /g, "-"))
          )
        }
      >
        {
          screenSize.width > 768 ?
            <div className="cover-contents">
              <h1 className="movie-title">
                {record.tmdb.title || record.tmdb.name || record.tmdb.original_name}
              </h1>
              <h3 className="movie-overview">
                {record.tmdb.overview && record.tmdb.overview.length > 200
                  ? record.tmdb.overview.substring(0, 200) + '...'
                  : record.tmdb.overview}
              </h3>
              <div className="cover-buttons">
                <button
                  className="btn-play"
                  onClick={() =>
                    navigate(
                      `${Constants.DB_DONWLOAD_RECORD_ROUTE.replace(":recordId", record.recordId)}`,
                      { state: { movie: record, userRole: "" } }
                    )
                  }
                >
                  <i className="fa fa-download"></i> Download
                </button>
                <button
                  className="btn-more"
                  onClick={() =>
                    navigate(
                      record.type.toLowerCase() === Constants.RECORD_TYPE_MOVIE
                        ? Constants.DB_MOVIE_DETIALS_ROUTE.replace(":title", record.recordId + "-" + record.name.toLowerCase().replace(/ /g, "-"))
                        : Constants.DB_SERIES_DETIALS_ROUTE.replace(":title", record.recordId + "-" + record.name.toLowerCase().replace(/ /g, "-"))
                    )
                  }
                >
                  <i className="fa fa-info-circle"></i> More Info
                </button>
              </div>
            </div> : null
        }

      </div>
      <div className="cover-controls">
        <button className="cover-prev" onClick={handlePrev}>◀</button>
        <button className="cover-next" onClick={handleNext}>▶</button>
      </div>

      {
        screenSize.width < 768 ? <div className="cover-contents">
          <div className="">
            <button
              className="btn-play mx-2"
              onClick={() =>
                navigate(
                  `${Constants.DB_DONWLOAD_RECORD_ROUTE.replace(":recordId", record.recordId)}`,
                  { state: { movie: record, userRole: "" } }
                )
              }
            >
              <i className="fa fa-download"></i> Download
            </button>
            <button
              className="btn-more mx-2"
              onClick={onToggleWatchlist}
            >
              {isWatchListed ? <i className="fa fa-check"> Listed</i> : <i className="fa fa-plus"> My List</i>}
            </button>
          </div>

        </div> : null
      }
    </div>
  );
}

function CoverSkeleton() {
  return (
    <div className="cover-skeleton">
      <div className="cover-skeleton-image"></div>
      <div className="cover-skeleton-contents">
        <div className="cover-skeleton-title skeleton"></div>
        <div className="cover-skeleton-overview skeleton"></div>
        <div className="cover-skeleton-buttons">
          <div className="skeleton btn-skeleton"></div>
          <div className="skeleton btn-skeleton"></div>
        </div>
      </div>
    </div>
  );
}

export default Cover;
