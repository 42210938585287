import Constants from "./Constants";
import { addUser, moviePageNumber, moviePageNumber_b, moviePageNumber_g, moviePageNumber_h, moviePageNumber_k, moviePageNumber_s, seriesPageNumber, seriesPageNumber_b, seriesPageNumber_g, seriesPageNumber_h, seriesPageNumber_k, seriesPageNumber_s } from "../redux/action/allActions";
import { useDispatch } from "react-redux";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

const getTimeDateFromTimeStamp = (timestamp, timezone) => {

    timestamp = parseInt(timestamp);

    let date = new Date(timestamp);

    let timeString = date.toLocaleTimeString();
    let dateString = date.toLocaleDateString();

    return { date: dateString || null, time: timeString || null };
}

const getHHmmFromSeconds = (d) => {
    var date = new Date(null);
    date.setSeconds(d / 1000);
    return date.toISOString().substr(11, 8);
}

const valiadteToken = async () => {
    // const response = await fetch(Constants.VALIDATE_TOKEN_API);
    // if (response.status === 200) {
    //     return true;
    // }
    return true;
}

const getPercentage = (actual, total) => {
    let percentage = parseFloat((actual * 100) / total).toFixed(2)
    return percentage;
}

const bytesToReadbleFormat = (bytes) => {

    if (bytes == null || typeof (bytes) === "undefined") {
        return { value: 0, suffix: "Bytes" };
    }

    if (typeof (bytes) === "string") {
        bytes = parseFloat(bytes).toFixed(0);
    }

    if (bytes === 0) return { value: 0, suffix: "Bytes" };
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return { value: parseFloat((bytes / Math.pow(k, i)).toFixed(2)), suffix: sizes[i] };

    // var megabytes = bytes * 0.00000095367432;
    // var kilobytes = bytes * 0.00097656;
    // var gigabytes = megabytes * 0.00097656;

    // if (bytes < 1024) {
    //     return { value: bytes, suffix: "bytes" }
    // }
    // else if (kilobytes > 1 && kilobytes < 1024) {
    //     return { value: parseFloat(kilobytes).toFixed(2), suffix: "KB" }
    // }
    // else if (megabytes < 1024) {
    //     return { value: parseFloat(megabytes).toFixed(2), suffix: "MB" }
    // }
    // else if (megabytes > 1024) {
    //     return { value: parseFloat(gigabytes).toFixed(2), suffix: "GB" }
    // } else {
    //     return { value: null, suffix: null };
    // }
}

const modifySearchQuery = (query) => {
    return query.replace(":", " ")
        .replace("-", " ")
        .replace(".", " ")
        .replace("_", " ")
}

const convertKiBTobytes = (KiB) => {
    return parseFloat(parseFloat(KiB) * 1024).toFixed(2);
}

const convertMiBTobytes = (MiB) => {
    return parseFloat(parseFloat(MiB) * 1048576).toFixed(2);
}

const convertGiBTobytes = (GiB) => {
    return parseFloat(parseFloat(GiB) * 1073741824).toFixed(2);
}

const convertTobytes = (value, suffix) => {
    let bytes = null;
    if (typeof (value) === null || typeof (value) === "undefined" || typeof (suffix) === null || typeof (suffix) === "undefined") {
        return null;
    }

    if (typeof (value) === "string") {
        value = parseFloat(bytes).toFixed(0);
    }

    if (suffix === Constants.KIB) {
        bytes = convertKiBTobytes(value);
    } else if (suffix === Constants.MIB) {
        bytes = convertMiBTobytes(value);
    } else if (suffix === Constants.MIB) {
        bytes = convertGiBTobytes(value);
    }
    return bytes;
}

// Utility function to format milliseconds to a human-readable string
function formatETA(seconds) {
    if (seconds <= 0) return "0 sec";

    const years = Math.floor(seconds / (3600 * 24 * 365));
    const days = Math.floor((seconds % (3600 * 24 * 365)) / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (years > 0) {
        return `${years}y ${days}d ${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    } else if (days > 0) {
        return `${days}d ${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    } else if (hours > 0) {
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    } else if (minutes > 0) {
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    } else {
        return `${secs} sec`;
    }
}

const handleCopy = (text) => {
    try {
        const element = document.createElement("textarea");
        element.value = text;
        document.body.appendChild(element)
        element.select();
        document.execCommand("copy");
        document.body.removeChild(element);
    } catch (e) {
        alert(e)
    }
}

const handleDownload = (text, isAndroid) => {
    try {
        if (Capacitor.isNativePlatform()) {
            Browser.open(text)
        } else {
            window.open(text);
        }
    } catch (e) {
        alert(e)
    }
}

const getCurrentUser = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    return typeof (user) !== "undefined" ? user : null;
}

const isValidUrl = (url) => {
    if (/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)) {
        return true;
    } else {
        return false;
    }
}

const removeUserFromLocal = () => {
    // Local Storage
    localStorage.setItem('login', false);
    localStorage.setItem('user', null);
    localStorage.setItem('token', null);
}

const setUserInLocal = (user, token) => {
    // Local Storage
    localStorage.setItem('login', true);
    localStorage.setItem('user', user);
    localStorage.setItem('token', token);
}

const JSONToHTMLTable = (props) => {
    const { data, style } = props
    return (
        <div className="">
            <table className="table table-sm table-striped table-bordered table-responsive">
                <tbody>
                    {Object.keys(data).map((k) => (
                        <tr key={k}>
                            {!Array.isArray(data) &&
                                <th className="align-middle" scope="row" style={{ width: "10%" }} >
                                    {/* Convert snakes to space and capitalize for visual */}
                                    {k.replace(/_/g, ' ')}
                                </th>
                            }
                            {(() => {
                                if (data[k] && typeof data[k] === 'object') {
                                    return (
                                        <td className="align-middle" style={style}>
                                            <JSONToHTMLTable data={data[k]} style={style} />
                                        </td>
                                    )
                                }
                                return (
                                    <td className="align-middle">
                                        {data[k]}
                                    </td>
                                )
                            })()}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const convertMediaInfoToCustomFormat = (data, isSearchedFile) => {
    return data?.map(mediaFile => {
        let mediaDetails = {
            id: "",
            general: {},
            video: {},
            audio: [],
            subtitle: [],
            downloadUrl: "",
            streamUrl: ""
        }
        mediaDetails.id = mediaFile.id

        let tempUrl = `${window.location.origin}${isSearchedFile ? "/api/stream/watch/" : "/api/stream/watch/uuid/"}${mediaFile.id}?t=${localStorage.getItem("token")}`;
        if (window.location.port === "3000") {
            tempUrl = tempUrl.replace("3000", "9000")
        }
        mediaDetails.streamUrl = tempUrl;
        tempUrl = tempUrl.replace("/watch", "/download")
        mediaDetails.downloadUrl = tempUrl

        mediaFile?.trackInfos?.forEach(track => {
            if (track?.type === "General") {
                let general = {}
                general.fileName = mediaFile.fileName;
                general.fileSize = bytesToReadbleFormat(mediaFile?.fileSize).value + " " + bytesToReadbleFormat(mediaFile?.fileSize).suffix;
                general.duration = track?.duration;
                general.overallBitrate = bytesToReadbleFormat(track?.overallBitRate).value + " " + bytesToReadbleFormat(track?.overallBitRate).suffix + "/s";
                general.Description = track?.Description;
                mediaDetails.general = general;
            }
            if (track?.type === "Video") {
                let video = {}
                video.resolution = track?.width + "x" + track?.height;
                video.format = track?.codecID + " | " + track?.format + " | " + track?.formatProfile + " | " + bytesToReadbleFormat(track?.bitRate).value + " " + bytesToReadbleFormat(track?.bitRate).suffix + "/s";
                video.hdrDetails = track?.hdrFormat != null && track?.hdrFormat + " | " + track?.hdrFormatVersion + " | " + track?.hdrFormatCompatibility
                video.size = bytesToReadbleFormat(track?.streamSize).value + " " + bytesToReadbleFormat(track?.streamSize).suffix;
                mediaDetails.video = video;
            }
            if (track?.type === "Audio") {
                let audio = {}
                audio.language = track?.language;
                audio.format = track?.codecID + " | " + track?.format + " @ " + bytesToReadbleFormat(track?.bitRate).value + " " + bytesToReadbleFormat(track?.bitRate).suffix + "/s";
                // audio.bitrate = track?.bitRate;
                audio.size = bytesToReadbleFormat(track?.streamSize).value + " " + bytesToReadbleFormat(track?.streamSize).suffix;
                audio.channelInfo = track?.channels + " | " + track?.channelPositions;
                mediaDetails.audio.push(audio);
            }
            if (track?.type === "Text") {
                let subtitle = {}
                subtitle.format = track?.codecID + " " + track?.format + " @ " + bytesToReadbleFormat(track?.bitRate).value + " " + bytesToReadbleFormat(track?.bitRate).suffix + "/s";
                subtitle.language = track?.language;
                subtitle.size = bytesToReadbleFormat(track?.streamSize).value + " " + bytesToReadbleFormat(track?.streamSize).suffix;;
                mediaDetails.subtitle.push(subtitle);
            }
        });
        return mediaDetails;
    })
}

const rgbaToHex = (rgba) => {
    // Parse RGBA values
    const parts = rgba.substring(rgba.indexOf("(")).split(",");
    const r = parseInt(parts[0].substring(1).trim());
    const g = parseInt(parts[1].trim());
    const b = parseInt(parts[2].trim());
    const a = parseFloat(parts[3] ? parts[3].substring(0, parts[3].indexOf(")")).trim() : 1);

    // Convert to HEX components
    const toHex = (n) => {
        const hex = Math.round(n).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    // For fully opaque (alpha = 1), return 6-digit HEX
    if (a >= 1) return `#${toHex(r)}${toHex(g)}${toHex(b)}`;

    // For transparency, convert alpha to 0-255 scale and add to HEX
    const alphaByte = Math.round(a * 255);
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(alphaByte)}`;
};

const getImageUrlFromTmdb = (tmdb, type, quality = 'original') => {
    if (!tmdb) return null;
    let imagePath = null;
    let images = [];
  
    // Determine which image array to use based on type
    if (type === Constants.IMAGE_TYPE_POSTER) {
      images = tmdb.images?.posters || [];
    } else if (type === Constants.IMAGE_TYPE_BACKDROP) {
      images = tmdb.images?.backdrops || [];
    }
  
    if (images.length > 0) {
      // Define priority groups in order
      const priorityGroups = [
        ['en'],                                 // First priority: English
        ['hi'],                                 // Second priority: Hindi
        ['gu', 'ta', 'te', 'ml', 'kn', 'ko'],    // Third priority: any one from these
      ];
  
      let filtered = [];
  
      // Loop over each group in order and break once we find images
      for (let group of priorityGroups) {
        filtered = images.filter(
          img => group.includes(img.iso_639_1) && img.file_path !== null
        );
        if (filtered.length > 0) break;
      }
  
      // If no images found in the priority groups, fallback to any available image
      if (filtered.length === 0) {
        filtered = images.filter(img => img.file_path !== null);
      }
  
      // If we have images, randomly pick one
      if (filtered.length > 0) {
        const randomIndex = Math.floor(Math.random() * filtered.length);
        imagePath = filtered[randomIndex].file_path;
      }
    }
  
    // Final fallback if imagePath still not set
    if (!imagePath) {
      imagePath = type === Constants.IMAGE_TYPE_POSTER ? tmdb.poster_path : tmdb.backdrop_path;
    }
  
    return Constants.TMDB_IMAGE_BASE_URL
      .replace('{quality}', quality)
      .replace('{imagePath}', imagePath);
  };
  


export default {
    getTimeDateFromTimeStamp,
    getHHmmFromSeconds,
    getPercentage,
    formatETA,
    isValidUrl,
    JSONToHTMLTable,
    bytesToReadbleFormat,
    convertTobytes,
    valiadteToken,
    modifySearchQuery,
    handleCopy,
    handleDownload,
    getCurrentUser,
    removeUserFromLocal,
    setUserInLocal,
    convertMediaInfoToCustomFormat,
    rgbaToHex,
    getImageUrlFromTmdb
    // pageUpdate
}