// DownloadButton.js
import React from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import AndroidPlugins from "../../../../android-app-components/AndroidPlugins";

const DownloadButton = ({ downloadUrl, fileName }) => {
  const handleDownload = async () => {
    if (!downloadUrl) {
      toast.error("Download URL is not available.");
      return;
    }
    try {
      const result = await AndroidPlugins.MyDownloadManager.startDownload({
        url: downloadUrl,
        fileName
      });
      alert("Download started: " + result.fileName)
      toast.success(`Download started: ${result.fileName}`);
    } catch (error) {
      toast.error("Error starting download");
      console.error("Download error:", error);
    }
  };

  return (
    <Button size="sm" variant="outline-success" onClick={handleDownload}>
      <i className="fas fa-download"></i> Download
    </Button>
  );
};

export default DownloadButton;